'use client';
import React from 'react';
import { AppBar } from '@/app/components/appBar';
import {
  Alert,
  Box,
  ButtonBase,
  MobileStepper,
  Typography,
  useMediaQuery,
  useTheme
} from '@mui/material';
import { usePathname } from 'next/navigation';
import dynamic from 'next/dynamic';
import { AlertContext } from '@/app/components/alertProvider';

export default dynamic(() => Promise.resolve(Layout), { ssr: false });

function Layout({ children }: { children: React.ReactNode }) {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('md'));

  return (
    <>
      <AlertContext.Consumer>
        {({ alert }) =>
          alert && (
            <Alert
              variant={'filled'}
              severity={alert.severity}
              sx={{
                position: 'fixed',
                top: 10,
                right: 20,
                minWidth: '200px',
                zIndex: 999
              }}
            >
              {alert.message}
            </Alert>
          )
        }
      </AlertContext.Consumer>
      <AppBar
        rightContent={
          <ButtonBase
            onClick={() => {
              window.location.href = 'tel:+18443676742';
            }}
          >
            {matches ? (
              <Typography variant="h6">
                Questions? Call{' '}
                <a
                  href="tel:+18443676742"
                  style={{
                    color: theme.typography.overline.color,
                    textDecoration: 'none'
                  }}
                >
                  (844) 367-6742
                </a>
              </Typography>
            ) : (
              <Typography
                variant="h6"
                style={{
                  color: theme.typography.overline.color
                }}
              >
                Contact Us
              </Typography>
            )}
          </ButtonBase>
        }
        progress={<TrailheadStepper />}
      />
      <Box
        position="fixed"
        top="80px"
        minHeight="calc(100vh - 80px)"
        minWidth="100vw"
      >
        {children}
      </Box>
    </>
  );
}

function TrailheadStepper() {
  const steps = 8;
  let activeStep = 0;

  const pathname = usePathname();

  if (pathname.includes('zip-code')) {
    activeStep = 1;
  } else if (pathname.includes('has-device')) {
    activeStep = 2;
  } else if (pathname.includes('select-product')) {
    activeStep = 3;
  } else if (pathname.includes('about-home')) {
    activeStep = 4;
  } else if (pathname.includes('recommendations')) {
    activeStep = 5;
  } else if (pathname.includes('installation-details')) {
    activeStep = 6;
  } else if (pathname.includes('summary')) {
    activeStep = 7;
  } else if (pathname.includes('no-services')) {
    activeStep = 6;
  } else if (pathname.includes('contact-info-submitted')) {
    activeStep = 7;
  }
  return (
    <MobileStepper
      variant="progress"
      position="static"
      sx={{
        minWidth: '200%',
        m: 0,
        p: 0,
        '& .MuiMobileStepper-progress': {
          minHeight: '8px',
          backgroundColor: 'rgb(200,200,200)'
        }
      }}
      backButton={null}
      nextButton={null}
      steps={steps}
      activeStep={activeStep}
    ></MobileStepper>
  );
}
